<template>
  <div class="secondary-body">
    <div class="container">
      <div class="column left"></div>
      <div class="column middle">
        <div class="site-logo">
          <img :src="userStore.logoSource" alt="cineflares" />
        </div>
      </div>
      <div class="column right"></div>
    </div>
    <section id="card-section">
      <div class="column left"></div>
      <div class="comumn middle">
        <div class="card-container">
          <h3 class="secondary-title">About</h3>
          <CardComponent
            v-for="(aboutItem, index) in aboutData"
            :key="index"
            :title="aboutItem.title"
            :description="aboutItem.content"
            :imageUrl="aboutItem.imageUrl"
          />
        </div>
      </div>
      <div class="column right"></div>
    </section>
  </div>
</template>

<script setup>
import "../styles/News.css";
import CardComponent from "../components/CardComponent.vue";
import { onBeforeMount, onUnmounted, ref } from "vue";
import { useUsersStore } from "@/stores/Users";
import { getAboutDetails } from "@/api/about";
import { useRouter } from "vue-router";

const aboutData = ref([]);
const router = useRouter();

const userStore = useUsersStore();

const fetchAbout = async () => {
  const data = await getAboutDetails();

  if (data.length === 0 || data?.error) {
    router.push("/");
  }

  aboutData.value = data;
};

// const NewsStore = useNewsStore();
onBeforeMount(async () => {
  fetchAbout();
  document.querySelector("body").classList.add("backgroundImage");
});

onUnmounted(() => {
  document.querySelector("body").classList.remove("backgroundImage");
});
</script>
