export const def_notableFilms = [{ name: null, link: null }];

export const lensEraChoices = [
  { id: "modern", name: "Modern" },
  { id: "vintage", name: "Vintage" },
];

export const flareTypeChoices = [
  { id: "streak", name: "Streak" },
  { id: "spherical-spot", name: "Spherical Spot" },
  { id: "oval", name: "Oval" },
  { id: "veiling", name: "Veiling" },
  { id: "rainbow", name: "Rainbow" },
  { id: "starburst", name: "Starburst" },
  { id: "ghost", name: "Ghost" },
  // { id: "narcissism", name: "Narcissism" },
  { id: "x-flare", name: "X-Flare" },
  { id: "caustic", name: "Caustic" },
  { id: "ring", name: "Ring" },
  { id: "anamorphic-spot", name: "Anamorphic Spot" },
  { id: "iris-blade-reflection", name: "Iris Blade Reflection" },
  { id: "diagonal-flare", name: "Diagonal Flare" },
];

export const flareColorChoices = [
  { id: "white", name: "White" },
  { id: "blue", name: "Blue" },
  { id: "red", name: "Red" },
  { id: "purple", name: "Purple" },
  { id: "cyan", name: "Cyan" },
  { id: "amber", name: "Amber" },
  { id: "yellow", name: "Yellow" },
  { id: "green", name: "Green" },
];

export const flareIntensityChoices = [
  { id: "strong", name: "Strong" },
  { id: "average", name: "Average" },
  { id: "low", name: "Low" },
];

export const flareFOVChoices = [
  { id: "wide", name: "Wide" },
  { id: "normal", name: "Normal" },
  { id: "long", name: "Long" },
];

export const loginFaqs = [
  {
    question: "What is CINEFLARES?",
    answer:
      "CINEFLARES is a unique web app that allows you to test and compare lenses for their flare and image characteristics with our time-code synced side by side player. ",
  },
  {
    question: "Is it free?",
    answer:
      "There is a basic library accessible for free. After creating a free user account, you can access the free base library. No credit card is needed and the free version will be free forever. (Availability of lenses in the free version is subject to change over time)",
  },
  {
    question: "What is CINEFLARES PRO?",
    answer:
      "CINEFLARES PRO is our large database intended for professional filmmakers. Currently we have over 65 lens sets published and we are adding new lenses on a monthly basis. Our PRO test database has currently 850+ individual lens test clips at different focal lengths and T-stops. CINEFLARES PRO is used by professional filmmakers from all over the world.",
  },
  {
    question: "What makes the CINEFLARES testing method different from other lens tests?",
    answer: "All lenses in our library are shot with the same camera body and light source under controlled conditions by ASC cinematographer Markus Forderer and an experienced team of professional filmmakers. We use a dedicated test setup with consistent lighting and motion controlled camera movement and exposure, ensuring highest accuracy. Our library is the largest of its kind world wide and has become an indispensable tool for many professionals in the film industry. "
  },
  {
    question: "What is the resolution of the videos.",
    answer: "All tests are shot on an 8K large format sensor to sample all details a lens has to offer. In postproduction, we downsample in the highest quality to 1920x1080p 10bit H265"
  },
  {
    question: "System requirements:",
    answer: "Cineflares is optimized to run on Mac and Windows computers \n. We recommend Chrome or Firefox browser to ensure frame accurate playback \n. Minimum internet speed of 25mbs recommended for smooth real-time playback."
  },
  {
    question: "Do you have an app?",
    answer: "We have a dedicated mobile version with a single player that runs on your phone's web browser. The side by side player is only available on a computer or large screen tablet."
  },
  {
    question: "Why lens flares only?",
    answer: "- We believe a lens flare is the fingerprint of a lens. By eliminating all visual distractions found in typical test scenarios and by focusing on the capture of a calibrated point light source in a controlled environment, each lens reveals its unique response to light. The lens flare shows aspects of most key characteristics of a lens, like dynamic contrast, color reproduction, flare pattern, iris geometry. We offer a unique way to compare lenses in a repeatable setting with our time-code synced side by side player. We are actively working on showing additional imaging characteristics. More soon."
  },
  {
    question: "I can't find my favorite lens in the database. Can I suggest lenses to add to Cineflares?",
    answer: 'Yes, you can request lenses here > <a href="mailto:contact@cineflares.com">suggest a lens</a>'
  },
  {
    question: "How often are you updating the library with new lenses?",
    answer: "We aim to add new lenses each month."
  },
  {
    question: "Can I download test videos?",
    answer: "- Currently we don't offer the ability to download files. If you are interested in liscencing high resolution or raw files for your project, please reach out to contact@cineflares.com"
  },
  {
    question: "I have feature requests or technical issues, how can I contact you?",
    answer: "Please reach out to us contact@cineflares.com. \nWe aim to respond same day."
  },
  {
    question: "Can I cancel my subscription anytime?",
    answer: "Sure, your paid subscription can be canceled anytime in your account settings. After the cancellation, your account will automatically downgrade to the basic free version at the end of your subscription period. You will have access to all pro features until the end of your renewal date."
  },
  {
    question: "Can I change my plan later on?",
    answer: "Absolutely! You can upgrade or downgrade your plan anytime. The money paid for the previous subscription will be recalculated to the new plan."
  },
  {
    question: "Will you renew my subscription automatically?",
    answer: 'Yes, your subscription will be automatically renewed according to your pay period. \nYou can manage and cancel your subscription <a href="https://lenses.cineflares.com/account/payment" target="_blank">here</a>. '
  },
  {
    question: "Do you offer any discounts?",
    answer: "Yes! We offer a discount for payment per year. This discount is shown on the pricing page."
  },
  {
    question: "Is there a student discount?",
    answer: 'We offer a special educational discount for actively enrolled students, faculty and staff. Please send an <a href="mailto:contact@cineflares.com">email</a> with the subject “educational discount” together with a copy of your student ID and we will get back to you as soon as possible.'
  },
  {
    question: "Why a subscription?",
    answer: "We continuously test lenses to the highest standard and develop new features and improvements to the CINEFLARES application. Your active subscription allows us to grow this unique project and gives you access to new and rare vintage lenses. We believe this is a unique platform to learn and discover the creative and technical potential of cine lenses."
  },
  {
    question: "If you are a lens manufacturer and would like us to test and add your latest lenses.",
    answer: `Please reach out to us via email <a href="mailto:contact@cineflares.com">here</a>.`
  },
  {
    question: "Data safety and privacy",
    answer: `We will never share your personal information. We do not access or save your payment information. Payments are handled by our payment provider <a href="https://lenses.cineflares.com/account/payment" target="_blank">Stripe.</a>`
  }
];
