<template>
  <div class="selectsBody secondary-body">
    <div class="container newsbody-container logo-container">
      <div class="column left"></div>
      <div class="column middle">
        <div class="site-logo">
          <img :src="userStore.logoSource" alt="cineflares" />
        </div>
      </div>
      <div class="column right"></div>
    </div>
    <section id="selects-section">
      <div class="container">
        <div class="column left"></div>
        <div class="column middle">
          <div class="title-row">
            <h3 class="secondary-title">Browse Lenses</h3>
            <div class="view-toggle-buttons">
              <img
                class="view-toggle-button"
                :class="{ active: browseView === 'grid' }"
                src="@/assets/img/grid-icon.svg"
                @click="
                  () => {
                    browseView = 'grid';
                  }
                "
                alt=""
              />
              <img
                class="view-toggle-button"
                :class="{ active: browseView === 'list' }"
                src="@/assets/img/list-icon.svg"
                @click="() => (browseView = 'list')"
                alt=""
              />
            </div>
          </div>
          <div class="browse-filter">
            <div class="browse-search">
              <SearchComponent
                @focus-event="searchFocus"
                @blur-event="searchBlur"
                :isLeftSearch="false"
                :isBrowse="true"
                @searchDataClick="
                  (data) => {
                    browseKeyword = data;
                  }
                "
              />
            </div>
            <div class="browse-dropdown">
              <select class="select-dropdown" v-model="focalLength">
                <option value="0">FOCAL LENGTH</option>
                <option
                  v-for="option in focalLengthOptions"
                  :key="option"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>
              <select class="select-dropdown" v-model="transmission">
                <option value="0" selected>T-STOP</option>
                <option
                  v-for="option in transmissionOptions"
                  :key="option"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>
            </div>

            <label
              v-for="(category, index) in lensCategory"
              :key="index"
              class="custom-browse-label custom-label-bigScreen"
            >
              <input
                type="radio"
                name="category"
                :value="category"
                v-model="selectedCategories"
                @click="unselectIfChecked"
              />
              <span>
                {{ category === "spherical" ? "spherical - s35" : category }}
              </span>
            </label>
            <!--For mobile-->
            <div class="browse-categories-mobile">
              <label
                v-for="(category, index) in lensCategory"
                :key="index"
                class="custom-browse-label"
              >
                <input
                  type="radio"
                  name="category-mb"
                  :value="category"
                  v-model="selectedCategories"
                  @click="unselectIfChecked"
                />
                <span>
                  {{ category }}
                </span>
              </label>
            </div>
          </div>
          <div v-if="loading" class="loader-div">
            <div class="loader" v-if="loading"></div>
          </div>
          <div
            class="select-row"
            :class="{ 'list-row': browseView === 'list' }"
            v-else-if="browseLens.length > 0"
          >
            <BrowseCard
              v-for="browse in browseLens"
              :key="browse.id"
              :browse="browse"
              @openDetailsPopup="openDetailsPopup"
              @addToWishlist="handleWishlistAdd(browse)"
              @removeFromWishList="removeFromWishList"
              :savedOnWishlist="checkWishlistStatus(browse.id)"
              :pendingArray="pendingArray"
            >
            </BrowseCard>
          </div>
          <div class="info-text" v-else>
            <p v-if="noResultFlag">
              No lens found. Please try different filter.
            </p>
            <p v-else>Apply your filter to search for lenses.</p>
          </div>
        </div>

        <div class="column right"></div>
      </div>
    </section>

    <PopupContainer
      v-if="showDetailsPopup"
      @closeAlert="showDetailsPopup = false"
    >
      <BrowseDetails
        @closePopup="showDetailsPopup = false"
        :lensDetails="lensDetails"
      />
    </PopupContainer>

    <div v-if="selectPdfDetails" style="display: none">
      <div ref="pdfRef">
        <SelectPDF :select="selectPdfDetails" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import SelectPDF from "@/components/Selects/SelectPDF.vue";
import BrowseCard from "@/components/browse/BrowseCard.vue";
import { useUsersStore } from "@/stores/Users";
import BrowseDetails from "@/components/browse/BrowseDetails.vue";
import PopupContainer from "@/components/PopupContainer.vue";
import { addToWishlist, removeFromWishlist } from "@/api/wishlist";
import { useWishlistStore } from "@/stores/Wishlist";
import SearchComponent from "@/components/SearchComponent.vue";
import client from "@/api/client";
import { ampli } from "@/ampli";
import { useRouter } from "vue-router";

// import savePdf from "@/includes/savePdf";
// import { useRouter } from "vue-router";
// import { useOverlayStore } from "@/stores/Overlay";
// const router = useRouter();

// const overlayStore = useOverlayStore();

const BROWSE_BASE_URL = `${process.env.VUE_APP_BASE_URL}/lens/browse?`;

const browseLens = ref([]);
const lensCategory = ref([]);
const focalLengthOptions = ref([]);
const transmissionOptions = ref([]);
const selectPdfDetails = ref(null);
const browseKeyword = ref("");
const focalLength = ref("0");
const transmission = ref("0");
const pdfRef = ref(null);
const debounceTimer = ref(null);
const noResultFlag = ref(false);
const lensDetails = ref();
const showDetailsPopup = ref(false);
const pendingArray = ref([]);
const browseView = ref("grid"); // gird | list
const loading = ref(false);
//const showDetailsPopup = ref(false);

const savedVideos = computed(() => wishlistStore.wishlist);

const selectedCategories = ref();

const userStore = useUsersStore();
const wishlistStore = useWishlistStore();

const router = useRouter();

let currentPathObject = router.currentRoute.value;

const unselectIfChecked = (event) => {
  if (event.target.value === selectedCategories.value) {
    selectedCategories.value = "";
  }
};

const fetchBrowsedLens = async () => {
  try {
    const baseBrowseUrl = `${process.env.VUE_APP_BASE_URL}/lens/browse?`;
    loading.value = true;

    const response = await client.get(baseBrowseUrl);

    const totalLens = response.data;
    let lensArray = [];
    let categoryArray = [];

    totalLens.forEach((category) => {
      categoryArray.push(category.name.toLowerCase());

      lensArray.push(category.lenses);
    });

    const flatLensArray = lensArray.flat();

    const focalLengths = [
      ...new Set(flatLensArray.map((lens) => lens.focalLength)),
    ];

    const transmissions = [
      ...new Set(flatLensArray.map((lens) => lens.transmission)),
    ];

    const compareFocal = (a, b) => {
      const numA = parseInt(a);
      const numB = parseInt(b);

      return numA - numB;
    };

    const compareTrans = (a, b) => {
      // Extract the numeric part of each string (remove "T" and parse as float)
      const numA = parseFloat(a.substring(1));
      const numB = parseFloat(b.substring(1));

      return numA - numB;
    };

    focalLengths.sort(compareFocal);
    transmissions.sort(compareTrans);
    focalLengthOptions.value = focalLengths;
    transmissionOptions.value = transmissions;

    lensCategory.value = categoryArray;
    browseLens.value = flatLensArray;
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
};

const buildEndpoint = () => {
  // Base URL
  let endpoint = BROWSE_BASE_URL;
  // let endpoint = 'http://localhost:3000/api/v1/lens/browse?';

  if (browseKeyword.value) {
    endpoint += `searchQuery=${browseKeyword.value}&`;
  }

  if (focalLength.value && focalLength.value !== "0") {
    endpoint += `focalLength=${focalLength.value}&`;
  }

  if (transmission.value && transmission.value !== "0") {
    endpoint += `transmission=${transmission.value}&`;
  }

  if (selectedCategories.value) {
    endpoint += `lensCategory=${encodeURI(selectedCategories.value)}&`;
  }

  if (endpoint.endsWith("&")) {
    endpoint = endpoint.slice(0, -1);
  }

  return endpoint;
};

const fetchFilteredBrowse = async () => {
  try {
    const builtEndpoint = buildEndpoint();

    const response = await client.get(builtEndpoint);

    const totalLens = response.data;
    let lensArray = [];

    totalLens.forEach((category) => {
      category.lenses.forEach((lens) => {
        lens.categoryName = category.name;
      });
      lensArray.push(category.lenses);
    });

    const flatLensArray = lensArray.flat();

    flatLensArray.length === 0
      ? (noResultFlag.value = true)
      : (noResultFlag.value = false);

    browseLens.value = flatLensArray;
  } catch (err) {
    console.log(err);
  }
};
const fetchSelectDetails = async (id) => {
  try {
    const response = await client.get(
      `${process.env.VUE_APP_BASE_URL}/lens/details/${id}`
    );

    lensDetails.value = response.data.lenses[0];
    lensDetails.value.categoryName = response.data.name;
  } catch (err) {
    console.log(err);
  }
};
const openDetailsPopup = async (id) => {
  await fetchSelectDetails(id);
  showDetailsPopup.value = true;
};

const checkWishlistStatus = (lensId) =>
  savedVideos.value.some(
    (item) => item.lensId === lensId && !item.sceneImageUrl
  );

const handleWishlistAdd = async (browse) => {
  pendingArray.value.push(browse.id);

  const payload = {
    lensId: browse.id,
    name: browse.name,
    focalLength: browse.focalLength,
    transmission: browse.transmission,
    flareImage: browse.flareImage || browse.preview,
    videoPath: browse.videoPath,
    categoryName: browse.categoryName,
  };

  /*
    AMPLITUDE: Track lens saved
    Triggers when a lens save button is clicked
  */
  ampli.lensSaved({
    lensId: payload.lensId,
    lensName: payload.name,
    focalLength: payload.focalLength,
    transmission: payload.transmission,
    page: currentPathObject?.fullPath,
  });

  const response = await addToWishlist(payload);

  if (response.status === 200) {
    wishlistStore.addToWishlist(response.data);
  }

  pendingArray.value = pendingArray.value.filter((item) => item !== browse.id);
};

const removeFromWishList = async (lensId) => {
  pendingArray.value.push(lensId);

  const wishlistData = savedVideos.value.find(
    (wishlist) => wishlist.lensId === lensId && !wishlist.sceneImageUrl
  );

  const response = await removeFromWishlist(wishlistData.id);

  if (response.status === 200) {
    wishlistStore.removeFromWishlist(wishlistData.id);
  }

  pendingArray.value = pendingArray.value.filter((item) => item !== lensId);
};

watch(browseKeyword, async () => {
  if (debounceTimer.value) clearTimeout(debounceTimer.value);

  debounceTimer.value = setTimeout(() => {
    fetchFilteredBrowse();
  }, 500);
});
watch(focalLength, async () => {
  fetchFilteredBrowse();
});
watch(transmission, async () => {
  fetchFilteredBrowse();
});
watch(selectedCategories, async () => {
  fetchFilteredBrowse();
});

onMounted(async () => {
  await fetchBrowsedLens();
});
</script>

<style scoped>
.middle {
  flex: 5;
  display: block;
}

.select-row {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .logo-container {
    display: none;
  }

  .secondary-body {
    padding-top: 0;
    min-height: 70vh;
  }
}

.select-dropdown {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  background: transparent;
  color: rgba(255, 255, 255, 0.7);
  padding: 0px 5px;
  width: 130px;
  cursor: pointer;
  height: 34px;
  font-size: 12px;
}

.select-dropdown option {
  color: black;
}

.browse-filter {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 10px;
}

.browse-dropdown {
  display: flex;
  gap: 10px;
}

.category-box {
  display: flex;
  gap: 10px;
}

.custom-browse-label input {
  display: none;
}

.custom-browse-label span {
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.7);
  padding: 5px 30px;
  border-radius: 20px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  height: 34px;

  cursor: pointer;
  position: relative;
}

.custom-browse-label span::after {
  content: "\2713";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  display: none;
}
.custom-browse-label span::after {
  content: "\2713";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  display: none;
}

.custom-browse-label input:checked + span {
  background-color: #666666;
}

.custom-browse-label input:checked + span::after {
  display: block;
}

.search-input-field {
  margin-right: 0;
}

.loader-div {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .browse-filter {
    gap: 10px;
  }

  .custom-browse-label span {
    font-size: 10px;
  }
}

.browse-categories-mobile {
  display: none;
}

@media screen and (max-width: 720px) {
  .search-input-field {
    max-width: none;
  }
  .browse-search {
    flex-basis: 100%;
  }

  .custom-label-bigScreen {
    display: none;
  }

  .browse-categories-mobile {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    max-width: calc(100vw - 40px);
    padding: 10px 0;
  }

  .search-input {
    width: 100%;
    background: rgba(51, 152, 219, 0.2);
    padding: 12px 60px 12px 20px;
    color: white;
    border: none;
    outline: none;
    border-radius: 30px;
    font-size: 16px;
  }

  .select-dropdown {
    width: 100%;
    background-color: black;
    color: white;
    border: 0;
    box-shadow: none;
    padding: 0 10px;
  }

  .select-dropdown option {
    color: white;
  }

  .browse-dropdown {
    flex: 1;
  }
}
</style>
