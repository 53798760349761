<template>
  <div class="section-container">
    <div class="image-container">
      <img :src="imageUrl" alt="News" />
    </div>
    <div class="text-container">
      <div class="title">
        {{ title }}
      </div>
      <span class="subtitle" v-if="date">{{ formattedDate }} </span>
      <div v-html="description"></div>
      <table></table>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";

const formattedDate = ref("");
const props = defineProps({
  title: String,
  description: String,
  date: String,
  imageUrl: String,
});

onMounted(() => {
  //format the date
  const options = { month: "long", day: "numeric", year: "numeric" };
  formattedDate.value = new Date(props.date).toLocaleDateString(
    undefined,
    options
  );
});
</script>
