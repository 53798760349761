/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/arbyte-solutions/CineflaresMain/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/arbyte-solutions/CineflaresMain/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [configuration]
 * @property {BrowserClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'production'|'development'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} production
 * @property {string} development
 */
export const ApiKey = {
  production: process.env.VUE_APP_AMPLITUDE_API_KEY,
  development: process.env.VUE_APP_AMPLITUDE_API_KEY
};

/**
 * @typedef {Object} Lens
 * @param {string} focalLength Focal length of lens (if in desktop/compare mode, focal length of left lens).
 * @param {string} lensCategoryId Lens category ID.
 * @param {string} lensId ID of lens (if in desktop/compare mode, ID of left lens).
 * @param {string} lensName Name of lens (if in desktop/compare mode, name of left lens).
 * @param {string} transmission Transmission of lens (if in desktop/compare mode, name of left lens).
 */

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'web',
    versionId: '5120e2fc-4e3d-4a97-a5a4-234adc19a84f'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export class Identify {
  constructor(properties) {
    this.event_type = amplitude.Types.SpecialEventType.IDENTIFY;
    this.event_properties = properties;
  }
}

export class LensSaved {
  constructor(properties) {
    this.event_type = 'Lens Saved';
    this.event_properties = properties;
  }
}

export class LensSearched {
  constructor(properties) {
    this.event_type = 'Lens Searched';
    this.event_properties = properties;
  }
}

export class LensVideoPlayed {
  constructor(properties) {
    this.event_type = 'Lens Video Played';
    this.event_properties = properties;
  }
}

export class LensWatched {
  constructor(properties) {
    this.event_type = 'Lens Watched';
    this.event_properties = properties;
  }
}

/**
 * @typedef PromiseResult
 * @type {object}
 * @property {Promise|undefined} promise
 */

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {BrowserClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
  }

  /**
   * @return {BrowserClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   *
   * @return {PromiseResult}
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, { "autocapture": true });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set or update that user's properties.
   *
   * @param {string|undefined} userId The user's id.
   * @param {Object} [properties] The user's properties.
   * @param {string} [properties.emailId] Email ID of the user.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  identify(userId, properties, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = { ...options, user_id: userId };
    }

    const ampIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        ampIdentify.set(key, value);
      }
    }
    return this.amplitude.identify(ampIdentify, options);
  }

  /**
  * Flush the event.
  */
  flush() {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.flush();
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  track(event, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.track(event, undefined, options);
  }

  /**
   * Lens Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/arbyte-solutions/CineflaresMain/events/main/latest/Lens%20Saved)
   *
   * Event to track when a user saves a lens in the CineflaresMain platform.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.focalLength Focal length of lens (if in desktop/compare mode, focal length of left lens).
   * @param {string} properties.lensId ID of lens (if in desktop/compare mode, ID of left lens).
   * @param {string} properties.lensName Name of lens (if in desktop/compare mode, name of left lens).
   * @param {string} properties.page Page from where the event will be triggered.
   * @param {string} properties.transmission Transmission of lens (if in desktop/compare mode, name of left lens).
   * @param {EventOptions} [options] Options for this track call.
   */
  lensSaved(properties, options) {
    return this.track(new LensSaved(properties), options);
  }

  /**
   * Lens Searched
   *
   * [View in Tracking Plan](https://data.amplitude.com/arbyte-solutions/CineflaresMain/events/main/latest/Lens%20Searched)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.page Page from where the event will be triggered.
   * @param {string} properties.query Search text for lenses.
   * @param {EventOptions} [options] Options for this track call.
   */
  lensSearched(properties, options) {
    return this.track(new LensSearched(properties), options);
  }

  /**
   * Lens Video Played
   *
   * [View in Tracking Plan](https://data.amplitude.com/arbyte-solutions/CineflaresMain/events/main/latest/Lens%20Video%20Played)
   *
Event has no description in tracking plan.
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.leftFocalLength Focal length of lens (if in desktop/compare mode, focal length of left lens).
   * @param {string} properties.leftLensCategoryId Lens category ID.
   * @param {string} properties.leftLensId ID of lens (if in desktop/compare mode, ID of left lens).
   * @param {string} properties.leftLensName Name of lens (if in desktop/compare mode, name of left lens).
   * @param {string} properties.leftTransmission Transmission of lens (if in desktop/compare mode, name of left lens).
   * @param {string} [properties.rightFocalLength] Right lens focal length on display, empty in mobile view.
   * @param {string} [properties.rightLensCategoryId] Lens category ID.
   * @param {string} [properties.rightLensId] Right lens id on display, empty in mobile view.
   * @param {string} [properties.rightLensName] Right lens name on display, empty in mobile view.
   * @param {string} [properties.rightTransmission] Right lens transmission on display, empty in mobile view.
   * @param {EventOptions} [options] Options for this track call.
   */
  lensVideoPlayed(properties, options) {
    return this.track(new LensVideoPlayed(properties), options);
  }

  /**
   * Lens Watched
   *
   * [View in Tracking Plan](https://data.amplitude.com/arbyte-solutions/CineflaresMain/events/main/latest/Lens%20Watched)
   *
   * This event tracks when a user plays a lens video on the Cineflares platform.
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.comparedLensId] Right lens id on display, empty in mobile view.
   * @param {string} properties.focalLength Focal length of lens (if in desktop/compare mode, focal length of left lens).
   * @param {string} properties.lensCategoryId Lens category ID.
   * @param {string} properties.lensId ID of lens (if in desktop/compare mode, ID of left lens).
   * @param {string} properties.lensName Name of lens (if in desktop/compare mode, name of left lens).
   * @param {string} properties.transmission Transmission of lens (if in desktop/compare mode, name of left lens).
   * @param {EventOptions} [options] Options for this track call.
   */
  lensWatched(properties, options) {
    return this.track(new LensWatched(properties), options);
  }
}

export const ampli = new Ampli();
