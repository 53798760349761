<template>
  <div class="search-box">
    <div
      :class="['search-input-field-wrapper', { expanded: isExpanded }]"
      :style="{
        marginLeft: props.isScene
          ? '8px'
          : props.isLeftSearch === true
          ? '48px'
          : '28px',
        marginRight: props.isScene && '8px',
      }"
    >
      <div class="search-icon" @click="handleSearchIconClick">
        <img
          :src="
            props.isScene === true
              ? require('../assets/img/search_icon_black.png')
              : require('../assets/img/search_icon.png')
          "
          alt=""
        />
      </div>
      <input
        ref="searchInput"
        type="text"
        class="search-input"
        @focus="handleSearchFocus"
        @blur="handleSearchBlur"
        v-model="searchKeyword"
        :style="{
          backgroundColor: props.isScene === true && '#EBEBEB',
          color: props.isScene === true && 'black',
        }"
      />
    </div>
    <div
      class="scene-search-result-box"
      :style="{
        marginLeft:
          props.isScene === true
            ? '0px'
            : props.isLeftSearch === true
            ? '48px'
            : '28px',
      }"
      v-if="showSearchResults"
    >
      <div class="search-results">
        <div v-if="loading" class="loading-div">
          <l-line-spinner
            size="20"
            stroke="2"
            speed="1"
            color="rgba(255,255,255,0.5)"
          ></l-line-spinner>
        </div>
        <div v-else>
          <div v-if="searchKeyword === '' && searchData.length === 0">
            Enter your keyword
          </div>
          <div v-if="searchData.length > 0">
            <div
              class="search-result"
              v-for="search in searchData"
              :key="search"
            >
              <div class="search-head">{{ search.categoryName }}</div>
              <div
                class="search-data"
                v-for="lenses in search.filteredLenses"
                :key="lenses.name"
                :class="{ 'search-scene-data': isScene }"
                @click="handleSearchDataClick(lenses.name)"
              >
                <span>{{ lenses.name }}</span>
                <span
                  v-if="props.isScene && lenses.isNew"
                  class="new-badge"
                  style="font-size: 10px"
                  >new</span
                >
              </div>
            </div>
          </div>
          <div v-if="searchKeyword !== '' && searchData.length === 0">
            No results found.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useLensesStore } from "@/stores/Lenses";
import { useScenesStore } from "@/stores/Scenes";
import { defineProps, defineEmits, ref, watch } from "vue";
import { lineSpinner } from "ldrs";
import client from "@/api/client";
import { ampli } from "@/ampli";
import { useRouter } from "vue-router";

lineSpinner.register();

const props = defineProps(["isLeftSearch", "isScene"]);
const lensStore = useLensesStore();
const sceneStore = useScenesStore();

const searchData = ref([]);
const debounceTimer = ref(null);

const emits = defineEmits(["focusEvent", "blurEvent"]);

const searchKeyword = ref("");
const showSearchResults = ref(false);
const loading = ref(false);
const isExpanded = ref(false);
const searchInput = ref(null);

const router = useRouter();

let currentPathObject = router.currentRoute.value;

const handleSearchIconClick = () => {
  isExpanded.value = true;
  searchInput.value.focus();
};

const handleSearchFocus = () => {
  emits("focusEvent");

  showSearchResults.value = true;
  if (props.isScene) {
    sceneStore.searchIsActive = true;
  } else {
    lensStore.searchIsActive = true;
  }
  isExpanded.value = true;
};

const handleSearchBlur = () => {
  emits("blurEvent");

  setTimeout(() => {
    searchKeyword.value = "";
    searchData.value = [];

    showSearchResults.value = false;
    if (props.isScene) {
      sceneStore.searchIsActive = false;
    } else {
      lensStore.searchIsActive = false;
    }
  }, 200);
  isExpanded.value = false;
};

const removeDublicates = (data) => {
  let lensDict = {};

  // Iterate through the array
  data.forEach((lens) => {
    if (!lensDict[lens.name]) {
      // If lens name not in dict, add it
      lensDict[lens.name] = { name: lens.name, isNew: lens.isNew };
    } else {
      // If lens name is already in dict, update isNew state
      if (lens.isNew) {
        lensDict[lens.name].isNew = true;
      }
    }
  });

  // Convert the dictionary back to an array
  let uniqueLenses = Object.values(lensDict);

  return uniqueLenses;
};

const handleSearchDataClick = (name) => {
  if (props.isScene) {
    if (props.isLeftSearch) {
      sceneStore.setLeftSelectedVideoId(
        name.toLowerCase().replace(/\s+/g, "-") + "-left"
      );
    } else {
      sceneStore.setRightSelectedVideoId(
        name.toLowerCase().replace(/\s+/g, "-") + "-right"
      );
    }
  } else {
    if (props.isLeftSearch) {
      lensStore.setLeftSelectedVideoId(
        name.toLowerCase().replace(/\s+/g, "-") + "-left"
      );
    } else {
      lensStore.setRightSelectedVideoId(
        name.toLowerCase().replace(/\s+/g, "-") + "-right"
      );
    }
  }

  searchKeyword.value = "";
};

const searchLens = async () => {
  const endPoint = props.isScene
    ? "/lens/scenes/search?searchQuery="
    : "/lens/search?query=";
  try {
    /*
      AMPLITUDE: Track lens search
      Triggers when a lens search API is called
    */
    ampli.lensSearched({
      query: searchKeyword?.value,
      page: currentPathObject?.fullPath,
    });
    const response = await client.get(
      `${process.env.VUE_APP_BASE_URL}${endPoint}${searchKeyword.value}`
    );

    let unfilteredArray = [];

    unfilteredArray = props.isScene ? response.data : response.data.lens;
    let filteredArray = [];

    unfilteredArray.forEach((array) => {
      const lensObject = array.lenses?.map((lens) => {
        return { name: lens.name, isNew: lens.isNew };
      });
      const filteredLenses = removeDublicates(lensObject);

      filteredArray.push({
        categoryName: array.name,
        filteredLenses,
      });
    });

    searchData.value = filteredArray;
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
};

watch(searchKeyword, async () => {
  if (!searchKeyword.value) {
    searchData.value = [];
    return;
  }
  if (debounceTimer.value) clearTimeout(debounceTimer.value);

  loading.value = true;

  debounceTimer.value = setTimeout(() => {
    searchLens();
  }, 1000);
});
</script>

<style scoped>
.scene-search-data {
  display: flex;
  gap: 5px;
}

.scene-new-data .new-badge {
  height: fit-content;
}

.scene-new-data {
  font-size: 12px;
}

.search-scene-data {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.search-scene-data .new-badge {
  height: fit-content;
}

.search-input-field-wrapper {
  position: absolute;
}

.search-input-field {
  display: flex;
  align-items: center;
}

.search-icon {
  cursor: pointer;
}

.search-input {
  width: 100px;
  transition: width 0.4s ease;
}

.search-input-field-wrapper.expanded .search-input {
  position: absolute;
  width: 20vw;
  max-width: 320px;
  z-index: 1000;
}

.scene-search-result-box {
  width: 20vw;
  max-width: 320px;
  max-height: 300px;
  overflow-y: auto;
  background: rgba(39, 38, 38, 0.932);
  backdrop-filter: blur(1px);
  border-radius: 20px;
  padding: 20px;
  position: absolute;
  top: 100%;
  margin-top: 5px;
  z-index: 100;
  margin-right: 20px;
}
</style>
