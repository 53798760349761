import { defineStore } from "pinia";
import { getSceneBgSrc } from "@/api/scene";
import client from "@/api/client";
export const useScenesStore = defineStore("scenes", {
  state: () => ({
    base_URL: process.env.VUE_APP_BASE_URL,
    data: [],
    datafetched: false,
    lensesName: [],
    LeftSelectedVideoId: null,
    RightSelectedVideoId: null,
    defaultLeftVideoId: null,
    defaultRightVideoId: null,
    defaultMobileVideoId: null,
    LeftSelectedVideoDetails: [],
    RightSelectedVideoDetails: [],
    SelectedVideo: null,
    LeftFocalLength: null,
    RightFocalLength: null,
    LeftTransmission: null,
    RightTransmission: null,
    LeftVideoLoaded: false,
    RightVideoLoaded: false,
    focalLengthOptionsLeft: [],
    focalLengthOptionsRight: [],
    transmissionsLeft: [],
    transmissionsRight: [],
    videoControlBtn: "play",
    playVideo: false,
    pauseVideo: false,
    isPremiumUser: false,
    sceneType: "desert",
    //states for mobile version selected video
    SelectedVideoDetails: null,
    SelectedFocalLength: null,
    SelectedTransmission: null,
    debounceTimer: null,
    searchIsActive: false,
  }),
  getters: {
    getLeftSelectedVideoId: (state) => state.LeftSelectedVideoId,
    getRightSelectedVideoId: (state) => state.RightSelectedVideoId,
    getLeftFocalLength: (state) => state.LeftFocalLength,
    getRightFocalLength: (state) => state.RightFocalLength,
    getleftTransmission: (state) => state.LeftTransmission,
    getRightTransmission: (state) => state.RightTransmission,
    getLeftVideoLoaded: (state) => state.LeftVideoLoaded,
    getRightVideoLoaded: (state) => state.RightVideoLoaded,
    getLensesdata: (state) => state.data,
    getFilteredLenses: (state) => state.filteredLenses,
  },
  actions: {
    async fetchData() {
      try {
        const API_URL = `${this.base_URL}/lens/scenes`;
        const response = await client.get(API_URL);
        this.data = response?.data || [];
        this.isPremiumUser = response.data?.isPremiumUser;
        this.setLensesName();
        this.setDataFetched();
        this.setDefaultLeftVideo();
        this.setDefaultRightVideo();
        this.setDefaultMobileVideo();
        this.setLensesName();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    setDataFetched() {
      this.datafetched = true;
    },

    setLensesName() {
      if (this.data) {
        // Conversion logic
        const uniqueLensArray = this.data.map((category) => {
          const uniqueLensNames = new Set();
          const lenses = category.lenses
            .filter((lens) => {
              if (uniqueLensNames.has(lens.name)) {
                return false;
              }
              uniqueLensNames.add(lens.name);
              return true;
            })
            .map((lens) => ({
              name: lens.name,
            }));

          return {
            category: category.name,
            lenses: lenses,
          };
        });

        this.lensesName = uniqueLensArray;
      }
    },
    setDefaultLeftVideo() {
      let defaultArray = [];
      this.data.forEach((item) => {
        item.lenses.forEach((lens) => {
          if (lens.defaultLeft) {
            defaultArray.push(lens.name);
          }
        });
      });
      this.defaultLeftVideoId =
        defaultArray.length > 0
          ? `${defaultArray[0].toLowerCase().replace(/\s+/g, "-")}-left`
          : null;
    },
    setDefaultRightVideo() {
      let defaultArray = [];

      this.data.forEach((item) => {
        item.lenses.forEach((lens) => {
          if (lens.defaultRight) {
            defaultArray.push(lens.name);
          }
        });
      });
      this.defaultRightVideoId =
        defaultArray.length > 0
          ? `${defaultArray[0].toLowerCase().replace(/\s+/g, "-")}-right`
          : null;
    },
    setDefaultMobileVideo() {
      let defaultArray = [];

      this.data.forEach((item) => {
        item.lenses.forEach((lens) => {
          if (lens.defaultLeft) {
            defaultArray.push(lens.name);
          }
        });
      });
      this.defaultMobileVideoId =
        defaultArray.length > 0
          ? `${defaultArray[0].toLowerCase().replace(/\s+/g, "-")}`
          : null;
    },
    setLeftSelectedVideoId(LeftVideoID) {
      this.LeftSelectedVideoId = LeftVideoID;
    },
    setRightSelectedVideoId(RightVideoID) {
      this.RightSelectedVideoId = RightVideoID;
    },
    setLeftFocalLength(LeftFocalLength) {
      this.LeftFocalLength = LeftFocalLength;
    },
    setRightFocalLength(RightFocalLength) {
      this.RightFocalLength = RightFocalLength;
    },
    setLeftTransmission(LeftTransmission) {
      this.LeftTransmission = LeftTransmission;
    },
    setRightTransmission(RightTransmission) {
      this.RightTransmission = RightTransmission;
    },
    toggleLeftVideoLoaded() {
      this.LeftVideoLoaded = !this.LeftVideoLoaded;
    },
    toggleRightVideoLoaded() {
      this.RightVideoLoaded = !this.RightVideoLoaded;
    },
    setSelectedVideo(lenseName) {
      this.SelectedVideo = lenseName;
    },
    setSelectedFocalLength(focalLength) {
      this.SelectedFocalLength = focalLength;
    },
    setSelectedTransmission(transmission) {
      this.SelectedTransmission = transmission;
    },
    setSceneType(scene) {
      this.sceneType = scene;
    },
    setLeftSelectedVideoDetails() {
      const defaultArray = this.data.map((d) => d.lenses);
      const flatArray = defaultArray.flat();

      const middleObject = flatArray.filter(
        (fa) =>
          fa.name.toLowerCase().replace(/\s+/g, "-") + "-" + "left" ===
          this.LeftSelectedVideoId
      );

      this.LeftSelectedVideoDetails = middleObject;
      this.LeftSelectedVideoDetails.sort((a, b) => {
        const focalLengthA = a.focalLength
          ? parseFloat(a.focalLength.replace("mm", ""))
          : 0;
        const focalLengthB = b.focalLength
          ? parseFloat(b.focalLength.replace("mm", ""))
          : 0;
        const transmissionA = a.transmission
          ? parseFloat(a.transmission.replace("T", ""))
          : 0;
        const transmissionB = b.transmission
          ? parseFloat(b.transmission.replace("T", ""))
          : 0;
        const focalDiff = focalLengthA - focalLengthB;
        if (focalDiff !== 0) {
          return focalDiff;
        }
        return transmissionA - transmissionB;
      });
    },
    setRightSelectedVideoDetails() {
      const defaultArray = this.data.map((d) => d.lenses);
      const flatArray = defaultArray.flat();

      const middleObject = flatArray.filter(
        (fa) =>
          fa.name.toLowerCase().replace(/\s+/g, "-") + "-" + "right" ===
          this.RightSelectedVideoId
      );
      this.RightSelectedVideoDetails = middleObject;
      this.RightSelectedVideoDetails.sort((a, b) => {
        const focalLengthA = a.focalLength
          ? parseFloat(a.focalLength.replace("mm", ""))
          : 0;
        const focalLengthB = b.focalLength
          ? parseFloat(b.focalLength.replace("mm", ""))
          : 0;
        const transmissionA = a.transmission
          ? parseFloat(a.transmission.replace("T", ""))
          : 0;
        const transmissionB = b.transmission
          ? parseFloat(b.transmission.replace("T", ""))
          : 0;
        const focalDiff = focalLengthA - focalLengthB;
        if (focalDiff !== 0) {
          return focalDiff;
        }
        return transmissionA - transmissionB;
      });
    },
    setSelectedVideoDetails() {
      const defaultArray = this.data.map((d) => d.lenses);
      const flatArray = defaultArray.flat();

      const middleObject = flatArray.filter(
        (fa) =>
          fa.name.toLowerCase().replace(/\s+/g, "-") === this.SelectedVideo
      );
      this.SelectedVideoDetails = middleObject;
      this.SelectedVideoDetails.sort((a, b) => {
        const focalLengthA = a.focalLength
          ? parseFloat(a.focalLength.replace("mm", ""))
          : 0;
        const focalLengthB = b.focalLength
          ? parseFloat(b.focalLength.replace("mm", ""))
          : 0;
        const transmissionA = a.transmission
          ? parseFloat(a.transmission.replace("T", ""))
          : 0;
        const transmissionB = b.transmission
          ? parseFloat(b.transmission.replace("T", ""))
          : 0;
        const focalDiff = focalLengthA - focalLengthB;
        if (focalDiff !== 0) {
          return focalDiff;
        }
        return transmissionA - transmissionB;
      });
    },
    generateVideoLabelText(label, target) {
      const labelLeft =
        this.LeftSelectedVideoDetails.length > 0
          ? this.LeftSelectedVideoDetails[0]?.name
          : "n/a";
      const labelRight =
        this.RightSelectedVideoDetails.length > 0
          ? this.RightSelectedVideoDetails[0]?.name
          : "n/a";

      label.value.textContent = target === "LEFT" ? labelLeft : labelRight;
    },
    removeDuplicates(arr) {
      return arr.filter((item, index, self) => {
        return (
          index === self.findIndex((t) => t.focalLength === item.focalLength)
        );
      });
    },
    generateTransmissionDropdown(array, focalLength) {
      return array.filter(
        (transmission) => transmission.focalLength === focalLength
      );
    },
    async generateDropDown(target, focalLengthElement, transmissionElement) {
      try {
        const tempArray =
          target === "LEFT"
            ? this.LeftSelectedVideoDetails
            : target === "RIGHT"
            ? this.RightSelectedVideoDetails
            : this.SelectedVideoDetails;

        let focalLengths = await this.removeDuplicates(tempArray);

        const transmission = await this.generateTransmissionDropdown(
          tempArray,
          focalLengths[0].focalLength
        );

        // Remove all options
        while (focalLengthElement.value.options.length > 0) {
          focalLengthElement.value.remove(0);
        }

        while (transmissionElement.value.options.length > 0) {
          transmissionElement.value.remove(0);
        }

        // Update data properties instead of manipulating the DOM directly
        target === "LEFT"
          ? focalLengths.forEach((option) => {
              this.focalLengthOptionsLeft.push(option.focalLength);
            })
          : focalLengths.forEach((option) => {
              this.focalLengthOptionsRight.push(option.focalLength);
            });

        focalLengthElement.value.style.display = "";
        target === "LEFT"
          ? transmission.forEach((option) => {
              this.transmissionsLeft.push(option.transmission);
            })
          : transmission.forEach((option) => {
              this.transmissionsRight.push(option.transmission);
            });

        transmissionElement.value.style.display = "";
      } catch (err) {
        console.log(err);
      }
    },
    generateIcon(icon, iconElem) {
      if (!icon || !iconElem) return;
      iconElem.value.src = icon;
      iconElem.value.style.display = "block";
    },
    loadDefaultVideoPath(videoRef, target) {
      // spinnerRef.value.style.display = "flex";
      // const sourceElement = videoRef.value.querySelector("source");
      const items =
        target === "LEFT"
          ? this.LeftSelectedVideoDetails
          : target === "RIGHT"
          ? this.RightSelectedVideoDetails
          : this.SelectedVideoDetails;

      let sourceItem = [];

      if (target === "LEFT") {
        sourceItem = items.filter((item) => {
          return (
            item.focalLength === this.LeftFocalLength &&
            item.transmission === this.LeftTransmission
          );
        });
      } else if (target === "RIGHT") {
        sourceItem = items.filter((item) => {
          return (
            item.focalLength === this.RightFocalLength &&
            item.transmission === this.RightTransmission
          );
        });
      }

      let source;
      switch (this.sceneType) {
        case "desert":
          source = sourceItem[0]?.sceneImage1;
          break;
        case "night":
          source = sourceItem[0]?.sceneImage2;
          break;
        case "interior":
          source = sourceItem[0]?.sceneImage3;
          break;
      }
      // sourceElement.src = item[0].videoPath;
      // Add event listener for load event to add the show class
      videoRef.value.addEventListener("load", function () {
        videoRef.value.classList.add("show");
      });
      videoRef.value.src =
        source || require("../assets/img/no_scene_available.jpg");

      // const posterImage = new Image();
      // posterImage.src = item[0].preview; // Specify the path to your poster image
      // posterImage.onload = () => {
      //   // Poster image has fully loaded and displayed, hide the spinner
      //   if (spinnerRef.value) spinnerRef.value.style.display = "none";
      // };

      // videoRef.value.load();
    },
    getCategoryId(lensId) {
      let categoryId = null;

      for (const category of this.data) {
        for (const lens of category.lenses) {
          if (lens.id === lensId) {
            categoryId = category.id;
            break;
          }
        }
        if (categoryId) {
          break;
        }
      }

      return categoryId;
    },
    async loadMobileScenes(lensSceneRef, backgroundSceneRef) {
      const items = this.SelectedVideoDetails;

      let sourceItem = [];

      sourceItem = items.filter((item) => {
        return (
          item.focalLength === this.SelectedFocalLength &&
          item.transmission === this.SelectedTransmission
        );
      });
      const categoryId = this.getCategoryId(sourceItem[0].id);
      const focalLength = this.SelectedFocalLength;
      let source;
      switch (this.sceneType) {
        case "desert":
          source = sourceItem[0]?.sceneImage1;
          break;
        case "night":
          source = sourceItem[0]?.sceneImage2;
          break;
        case "interior":
          source = sourceItem[0]?.sceneImage3;
          break;
      }

      const response = await getSceneBgSrc(
        categoryId,
        focalLength,
        this.sceneType.toUpperCase()
      );

      let bgSource;
      if (response) {
        bgSource = response?.data?.imageUrl;
      }

      // sourceElement.src = item[0].videoPath;
      // Add event listener for load event to add the show class
      lensSceneRef.value.addEventListener("load", function () {
        lensSceneRef.value.classList.add("show");
      });
      lensSceneRef.value.src =
        source || require("../assets/img/no_scene_available.jpg");

      backgroundSceneRef.value.addEventListener("load", function () {
        backgroundSceneRef.value.classList.add("show");
      });
      backgroundSceneRef.value.src =
        bgSource || require("../assets/img/no_scene_available.jpg");
    },

    debounce(callback, time) {
      window.clearTimeout(this.debounceTimer);
      this.debounceTimer = window.setTimeout(callback, time);
    },
    snapToClosestOption(element, isMobile) {
      const viewportList = element;
      const viewportFromTop = viewportList.getBoundingClientRect().top;

      const marginTopOfViewPort =
        parseInt(
          window
            .getComputedStyle(viewportList)
            .getPropertyValue("margin-top")
            .replace("px", "")
        ) || 0;

      const listItemsLink = viewportList.querySelectorAll(".nav-link");
      const viewportListHeightHalf =
        viewportList.getBoundingClientRect().height / 2;
      const viewportListCenter =
        viewportFromTop + viewportListHeightHalf - marginTopOfViewPort + 6; // 6 is added as the padding and margin of the list assuming it is static
      const listItemsLi = [];
      listItemsLink.forEach((link) => {
        if (link.closest(".nav-item")) {
          listItemsLi.push(link.closest(".nav-item"));
        }
      });
      let defaultLeft, defaultRight, defaultVideo;
      if (!isMobile) {
        defaultLeft = document.getElementById(
          this.defaultLeftVideoId || process.env.VUE_APP_DEFAULT_LEFT
        );
        defaultRight = document.getElementById(
          this.defaultRightVideoId || process.env.VUE_APP_DEFAULT_RIGHT
        );
      } else {
        defaultVideo = document.getElementById(
          this.defaultMobileVideoId || process.env.VUE_APP_DEFALULT_LENS
        );
      }
      let closestLi;
      let closest = listItemsLi[0].getBoundingClientRect().top;
      let closestDiff = Math.abs(viewportListCenter - closest);
      listItemsLi.forEach((item) => {
        const current = item.getBoundingClientRect().top;
        const currentDiff = Math.abs(viewportListCenter - current);

        //remove the bold font on the li which is not active
        item.classList.remove("active-li");
        if (!isMobile) {
          defaultLeft.classList.remove("active-li");
          defaultRight.classList.remove("active-li");
        } else {
          defaultVideo.classList.remove("active-li");
        }

        // Calculate the difference between the target and current number
        if (currentDiff <= closestDiff) {
          closest = current;
          // Update the closest number
          closestDiff = currentDiff;
          closestLi = item;
        }
      });

      //adds the active class where the font is bold
      closestLi.classList.add("active-li");
      const anchorElem = closestLi.querySelector("a");

      //check if the anchor text is already clicked
      if (anchorElem.getAttribute("data-clicked") === "true") {
        return;
      } else {
        // store.setLeftSelectedVideoId(anchorElem.getAttribute('id'))
        this.debounce(() => {
          if (!isMobile) {
            element.getAttribute("id") === "viewportListLeft"
              ? this.setLeftSelectedVideoId(anchorElem.getAttribute("id"))
              : this.setRightSelectedVideoId(anchorElem.getAttribute("id"));
          } else {
            this.setSelectedVideo(anchorElem.getAttribute("id"));
          }
        }, 700);
      }

      // 3d effect
      this.scroll3dEffect(viewportListCenter, isMobile);
    },
    scrollToItem(item) {
      if (!item) return;
      const li = item.parentElement;
      const scrollableList = li.parentElement.parentElement.parentElement;
      const scrollableListTop = scrollableList.getBoundingClientRect().top;
      const containerHeight = scrollableList.clientHeight;
      const mid = containerHeight / 2;
      const itemBounds = li.children[0].getBoundingClientRect();

      const scrollTo =
        scrollableList.scrollTop +
        itemBounds.top -
        scrollableListTop -
        mid +
        itemBounds.height / 2;

      scrollableList.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    },
    addHeightOntopAndBottom(element) {
      const viewportList = element.value;
      if (!viewportList) {
        return;
      }
      const lists = viewportList.querySelectorAll("li");
      const clientHeight = viewportList.clientHeight;
      const firstLink = document.querySelectorAll(".nav-link")[0];
      const lastListHeight = lists[lists.length - 1].clientHeight;

      const firstNavTitle = document.querySelectorAll(".nav-title")[0];

      // is mobile
      if (viewportList.classList.contains("viewport")) {
        // substracting the paddings and margins of the nested uls and lis assuming the structure and the values wont change
        viewportList.style.paddingTop = `${
          clientHeight / 2 -
          2 -
          firstNavTitle.clientHeight -
          2 -
          4 -
          2 - // 16 for the margin top of the nav-title added on css
          firstLink.clientHeight / 2
        }px`;
      } else {
        viewportList.style.paddingTop = `${
          clientHeight / 2 -
          2 -
          firstNavTitle.clientHeight -
          2 -
          4 -
          2 -
          10 - // 10 for the margin top of the nav-title added on css
          firstLink.clientHeight / 2
        }px`;
      }

      // substracting the paddings and margins of the nested uls and lis assuming the structure and the values wont change
      viewportList.style.paddingBottom = `${
        clientHeight / 2 - 2 - 2 - 4 - lastListHeight / 2
      }px`;
    },
    scroll3dEffect(viewportListCenter, isMobile) {
      const navEntities = document.querySelectorAll(".nav-entity");

      navEntities.forEach((item) => {
        let base = Math.min(
          item.getBoundingClientRect().top - viewportListCenter,
          180
        );

        base = Math.max(base, -180);

        item.style.transform = `rotateX(${base * 0.6}deg)`;
        if (base > 0) {
          item.style.transformOrigin = "top";
        } else {
          item.style.transformOrigin = "bottom";
        }
        if (isMobile) {
          item.style.opacity = 1 - Math.abs(base) * 0.01;
        }
      });
    },
    setVideoPathOnChange(lensesItem, videoRef) {
      if (!lensesItem) return;

      let source = lensesItem.sceneImage1;
      switch (this.sceneType) {
        case "desert":
          source = lensesItem.sceneImage1;
          break;
        case "night":
          source = lensesItem.sceneImage2;
          break;
        case "interior":
          source = lensesItem.sceneImage3;
          break;
      }
      // Add event listener for load event to add the show class
      videoRef.value.addEventListener("load", function () {
        videoRef.value.classList.add("show");
      });
      videoRef.value.src =
        source || require("../assets/img/no_scene_available.jpg");
      // const sourceElement = videoRef.value.querySelector("source");
      // sourceElement.src = lensesItem.videoPath;
      // videoRef.value.load();
    },
  },
});
